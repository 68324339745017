import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { FaCheckSquare } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const AppTableDetails = (props) => {
  const details = props.details ? JSON.parse(props.details) : null;
  const numKeys = Object.keys(details).length - 1;
  return (
    details ?
      <Col className={props.css}>
        <Col className='boxBorderShadow cellBorders'>
          <Row className='m-0 py-2 justify-content-center h3 fw-bold cellBorders topBordersRounded'>{props.title}</Row>
          {
            Object.keys(details).map((ele, index) => (
              <Row className="col-12 mx-auto p-0" key={"atd_r" + index}>
                {
                  ele === "note" ?
                    <Row className={'mx-0 cellBorders ' + (numKeys == index ? "bottomBordersRounded" : "")}>
                      <Col className='m-0 h-100 p-2 col-12 text-start d-flex align-items-center fw-bold fs-5'>{details[ele]}</Col>
                    </Row>
                    :
                    <Row className={'mx-0 cellBorders ' + (numKeys == index ? "bottomBordersRounded" : "")}>
                      <Col className='mx-0 my-auto h-auto px-2 py-1 col-6 text-end d-flex justify-content-end align-items-center fw-bold cellBorderR fs-5'>
                        {
                          (
                            details[ele] === true ?
                              <FaCheckSquare size={34} style={{color: "#00cc00"}}/>
                              :
                              (
                                details[ele] === false ?
                                  <ImCross size={34} style={{color: "#ff0000"}}/>
                                  :
                                  (
                                    ele === "Square meters" ? <>{details[ele]}<sup>2</sup></> : details[ele]
                                  )
                              )
                          )
                        }
                      </Col>
                      <Col className='mx-0 my-auto px-2 h-100 col-6 text-start d-flex align-items-center fw-bold cellBorderL fs-5'>{ele}</Col>
                    </Row>
                }
              </Row>
            ))
          }
        </Col>
      </Col>
      :
      <></>
  );
}
export default AppTableDetails
