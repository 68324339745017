import { Container, Row, Col, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Resources from 'Resources';
import { Icons } from 'Icons';

const Header = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const currentPage = pathname === "/" ? "/"
    : (pathname.substring(1, pathname.length) != "appDetails" ? pathname.substring(1, pathname.length)
      : pathname.substring(1, pathname.length) + search);

  return (
    <Container className='sticky-top px-0 pb-1 mt-0 pt-0' fluid>
      <Row className="mx-0 py-2 px-xl-2 align-items-center bg-rounded rounded-bottom navStyle">
        <Col className='col-auto d-none d-xl-block ps-3'>
          <Nav.Link href={Resources.pages.homepage.link}>
            <Icons get={"home"} size={32} />
          </Nav.Link>
        </Col>
        <Col className="d-flex d-xl-none justify-content-between align-items-center px-3 px-sm-4">
          <Col className='col-2'>
            <Navbar expand={'xxl xl lg md sm'}>
              <Navbar.Toggle className='bg-white bg-gradient border-3 border-primary' />
              <Navbar.Offcanvas placement="top" className="h-0">
                <Offcanvas.Header className="border-bottom d-flex py-2 navStyle">
                  <Offcanvas.Header className='col-1 m-0 py-2' closeButton />
                  <Container className='col-auto d-flex justify-content-center'>
                    <Container className='h1 fw-bold my-3'>MENU</Container>
                  </Container>
                  <Container className='col-1 m-1'></Container>
                </Offcanvas.Header>
                <Offcanvas.Body className='overflow-visible bg-white p-0'>
                  <Container className='border-bottom py-3' style={{ backgroundColor: "#00FFFF30" }} fluid>
                    <Row className='col-auto'>
                      {
                        Object.keys(Resources.pages).map(ele => {
                          return (
                            <Col key={Resources.pages[ele].key} className='col-12 d-flex justify-content-center my-2'>
                              <Nav.Link
                                href={Resources.pages[ele].link}
                                className={"d-inline-block text-center px-4 py-3 border border-3 border-primary rounded-5 fs-5 fw-bold " + (currentPage === Resources.pages[ele].link ? "headerBTNBorderShadow" : "")}
                                style={{backgroundColor: "#00FF0060"}}>
                                {Resources.pages[ele].text}
                              </Nav.Link>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </Container>
                  <Container className='border-bottom py-4' style={{ backgroundColor: "#00FFFF20" }} fluid></Container>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          </Col>
          <Col className='col-auto text-end text-sm-center text-nowrap mb-1 me-sm-0 fw-bold h1'>APPs ZAKINJA</Col>
          <Col className='col-2 d-none d-sm-inline'></Col>
        </Col>
        <Col className="col-auto p-1 ms-auto d-xl-block d-none">
          <Nav>
            {
              Object.keys(Resources.pages).map(ele => {
                return (
                  <Nav.Link key={Resources.pages[ele].key} href={Resources.pages[ele].link}
                    className={currentPage === Resources.pages[ele].link ? "fw-bold fs-5 headerBTNBorderShadow" : "fw-bold fs-5 text-body"}>
                    {Resources.pages[ele].text}</Nav.Link>
                )
              })
            }
          </Nav>
        </Col>
      </Row>
    </Container>
  );
}
export default Header